<template>
  <div class="removing">
    <el-form class="form">
      <el-form-item label="签署流程ID">
        <el-input v-model="pid" size="mini" style="width: 260rem !important;"></el-input>
        <el-button debounce @click="submit" style="margin-left: 20rem;" size="mini" type="primary">确定</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
	_config:{"route":{"path":"index","meta":{"title":"操作"}}},
  data(){
    return {
      pid:null
    }
  },
  methods:{
    submit(){
      this.$_register.post("http://api3.honghueducation.com/api/agreement/esign/revoke",{
        flow_id:this.pid
      }).then(res=>{
        if (res.status === 0){
          this.$message.success("操作成功!");
          this.pid = null;
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.removing{
  padding: 20rem 40rem;
  .form{
  }
}
</style>
